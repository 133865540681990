import React, { useState } from 'react';
import { PanelProps } from '@grafana/data';
import {  TimeRangePicker } from '@grafana/ui';

interface Props extends PanelProps {}

export const HosstedTimeRangePickerPanel: React.FC<Props> = (props) => {
  const [rangePickerValue, setRangePickerValue] = useState(props.timeRange);
  const handleRangeSelection = (value) => {
    setRangePickerValue(value);

    props.onChangeTimeRange(value);
  }

  return (
    <div>
      <div style={{'display': 'flex', 'gap': '4px', justifyContent: 'flex-end'}}>
        <TimeRangePicker
          value={rangePickerValue} 
          onChange={handleRangeSelection}
          isOnCanvas={true}
        />
      </div>
    </div>
  );
};
